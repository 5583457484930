import React, { useState } from "react";
import "./auth.scss";
import { At, EyeOff, EyeOn, Lock } from "../../utils/imgPaths";
import { Link } from "react-router-dom";

const Login = ({ isLoggedIn, setIsLoggedIn }) => {
  const [eye, setEye] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://www.pharmacompass.com/app/1/9/form/login/",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: JSON.stringify({
            email: email,
            password: password,
            token: "YWxhZGRpbjpvcGVuc2VzYW1l|android",
            agree: true,
          }),
        }
      );

      const data = await response.json();
      if (data?.status === 0) {
        setError(true);
        const cleanMessage = data?.message
          .replace(/<br>/g, " ")
          .replace(/<\/?span[^>]*>/g, "")
          .replace(/<\/?a[^>]*>/g, "")
          .replace(/<\/?[^>]+(>|$)/g, "");
        setErrorMsg(cleanMessage);
        setIsLoggedIn(true);
      } else {
        setError(false);
        setIsLoggedIn(false);
        localStorage.setItem("sessionId", data?.session?.id);
        localStorage.setItem("email", data?.session?.email);
        localStorage.setItem("firstName", data?.session?.fname);
        localStorage.setItem("lastName", data?.session?.lname);
        localStorage.setItem("client", data?.session?.client);
        localStorage.setItem("level", data?.session?.level);

        const userData = localStorage.getItem("email");
        console.log('userData',userData)
        const now = new Date().getTime();
        const expirationTime = now + 7 * 24 * 60 * 60 * 1000;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('expiration', expirationTime);
        window.location.reload();
      }

      if (response.ok) {
        // setIsLoggedIn(false);
      } else {
        setError(data.message || "Login failed");
      }
    } catch (error) {
      setError("An error occurred while logging in.");
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <>
      <div className="form-position shadow-lg">
        <button className="close-login" onClick={() => setIsLoggedIn(false)}>
          ✖
        </button>
        <form className="form" onSubmit={handleLogin}>
          <h2 className="mb-4">Login</h2>
          {error && <p className="error-l text-danger">{errorMsg}</p>}
          <div className="flex-column">
            <label htmlFor="email">Email </label>
          </div>
          <div className="inputForm mb-2">
            <img src={At} alt="AtIcon" className="" height={22} width={22} />
            <input
              type="email"
              id="email"
              className="input"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="flex-column">
            <label htmlFor="password">Password </label>
          </div>
          <div className="inputForm">
            <img src={Lock} alt="Lock" className="" height={22} width={22} />
            <input
              type={eye === true ? "password" : "text"}
              id="password"
              className="input"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <img
              src={eye === true ? EyeOff : EyeOn}
              alt="eye-on-off-icon"
              className="me-2 cursor-pointer"
              height={22}
              width={22}
              onClick={() => setEye(!eye)}
            />
          </div>

          <div className="flex-row justify-content-end">
            <Link
              className="text-decoration-none"
              target="_blank"
              to={`https://www.pharmacompass.com/forgot-password`}
            >
              <span className="span">Forgot password?</span>
            </Link>
          </div>

         <div className={`position-relative mt-3 mb-2 ${loading?'':'glare'}`}>
          <button type="submit" className="button-submit" disabled={loading}>
            {loading ? "Submit" : "Submit"}
          </button>
         </div>

          <p className="p">
            Don't have an account?
            <Link
              className="text-decoration-none"
              target="_blank"
              to={`https://www.pharmacompass.com/create-an-account`}
            >
              <span className="span">Sign Up</span>
            </Link>
          </p>
        </form>
      </div>
      <div className="overlay-fill"></div>
    </>
  );
};

export default Login;
