import React from "react";
import { Link } from "react-router-dom";
import {
  Blogs,
  Boxs,
  Home,
  Molecule,
  News,
  Notess,
  pill,
  Pills,
} from "../utils/imgPaths";
import Tooltip from "./ui-component/Tooltip";
import { HelpIcon } from "./ui-component/SvgIcon";

export const SideMenu = () => {
  const BASEURL = "https://pharmacompass.com/";
  const SiteUrls = ({ urlName, linkName, tooltip, imgName }) => (
    // <Tooltip content={tooltip} position="bottom-right">
      <Link
        to={`${BASEURL}/${urlName}`}
        className="text-decoration-none inherit-color mb-0 d-flex align-items-center w-100 pc-site-link mb-2 px-1 ps-2 gap-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={imgName}
          alt="pill-icon"
          className="pill-icon me-2"
          height={16}
          width={16}
        />
        <p className="mb-0">{linkName}</p>
      </Link>
    // </Tooltip>
  );
  return (
    <>
      <div className="" style={{ height: 67 }}></div>
      <Link
        to={`/`}
        className="text-decoration-none inherit-color mb-0 d-flex align-items-center w-100 pc-site-link mb-2 px-1 ps-2 gap-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <Hut/> */}
        <img
          src={Home}
          alt="pill-icon"
          className="pill-icon me-2"
          height={16}
          width={16}
        />
        <p className="mb-0">{`Home`}</p>
      </Link>

      <SiteUrls
        imgName={Boxs}
        urlName={`find-suppliers`}
        linkName={`API/FDF`}
        tooltip={`Find Suppliers, API Ref. Price, FDF Dossiers, Global sales and patents, Intermediate Suppliers`}
      />
      <SiteUrls
        imgName={Notess}
        urlName={`pharma-services`}
        linkName={`Services`}
        tooltip={`Find Services Providers`}
      />
      <SiteUrls
        imgName={Molecule}
        urlName={`pharma-excipients`}
        linkName={`Excipients`}
        tooltip={`Find Excipients Manufacturers`}
      />
      <SiteUrls
        imgName={Pills}
        urlName={`pipeline-prospector-drugs-in-development`}
        linkName={`Drugs in Dev.`}
        tooltip={`Check Deals & Pipeline Updates`}
      />
      <SiteUrls
        imgName={News}
        urlName={`pharma-and-biotech-news`}
        linkName={`News`}
        tooltip={`Scan Latest Pharma & Biotech News`}
      />
      <SiteUrls
        imgName={Blogs}
        urlName={`blog`}
        linkName={`Blog`}
        tooltip={`Gain invaluable market insights`}
      />
    </>
  );
};

export const WithoutLoginBlock = () => {
  return (
    <>
      <div className="px-3 py-3 mb-1 help-center">
        <h4 className="mb-0 p-0 d-flex mb-2">
          <HelpIcon />
          Help center
        </h4>
        <h4 className="mb-0 p-0 d-inline-flex flex-wrap">
          <Link
            to={`https://www.pharmacompass.com/terms-and-conditions`}
            target="_blank"
            className="text-decoration-none me-1"
          >
            Terms of use
          </Link>
          and
          <Link
            to={`https://www.pharmacompass.com/privacy-policy`}
            target="_blank"
            className="text-decoration-none"
          >
            Privacy Policy
          </Link>
        </h4>
      </div>
    </>
  );
};
