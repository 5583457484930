import React, { useState, useEffect } from "react";
import "./layout.scss";
import SingleAd from "./SingleAd";
import ExpandCollapse from "./ExpandCollapse";
import QusAns from "../chat-area/QusAns";
import Header from "./Header";
import Login from "./Auth/Login";
import { SideMenu, WithoutLoginBlock } from "./SideMenu";
import contextList from "../utils/static-category.json";
import { PC_API_LINK } from "../utils/globalFunctions";

const Layout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [history, setHistory] = useState([]);
  const [fetchHistory, setFetchHistory] = useState(false);

  const [clickedId, setClickedId] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [chatData, setChatData] = useState([]);

  const [contextIndex, setContextIndex] = useState(0);
  const [qusContext, setQusContext] = useState("");
  const [contextType, setContextType] = useState("API");

  const getChatHistory = async (type) => {
    const url = `${PC_API_LINK}/get_chat_history/`;
    setFetchHistory(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
          type: type && type !== undefined ? type : "API",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const jsonResponse = await response.json();
      setHistory(jsonResponse?.chat_history);
      setFetchHistory(false);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };
  const historyChat = async (chatId, index, type) => {
    if (chatId !== undefined) {
      setClickedId(chatId);
      setActiveIndex(index);
      const url = `${PC_API_LINK}/getchatdetails/`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            type: type && type !== undefined ? type : "API",
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        setChatData(jsonResponse);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    }
  };
  const getSessionData = () => {
    const userData = localStorage.getItem("user");
    const expiration = localStorage.getItem("expiration");
    const now = new Date().getTime();

    if (expiration && now > expiration) {
      localStorage.removeItem("user");
      localStorage.removeItem("expiration");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("client");
      localStorage.removeItem("level");
      return null;
    }

    return userData ? JSON.parse(userData) : null;
  };

  useEffect(() => {
    const userLoggedIn = localStorage?.getItem("email");
    getSessionData();
    if (userLoggedIn) {
      getChatHistory(contextType);
      historyChat();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getChatHistory(contextType);
  }, [contextIndex]);

  const loginCheck =
    localStorage?.getItem("email") === "tarun@alcheminternational.com";

  return (
    <>
      <main className="ai-pc d-flex px-0 w-100">
        <aside className="ai-query-history px-3">
          <div className="side-menu-link">
            <SideMenu />
            <div className="w-100 border-bottom-0 my-2"></div>
            {localStorage?.getItem("email") && (
              <>
                <h3 className="mb-2">Category</h3>
                <div className="use-context">
                  {loginCheck
                    ? contextList?.contextList?.map((item, index) => (
                        <button
                          key={index}
                          className={`${
                            item?.accessFortarun === true ? "" : "coming-soon"
                          }${index === contextIndex ? " active" : ""} `}
                          type="text"
                          disabled={item?.accessFortarun ? false : true}
                          onClick={() => {
                            setContextIndex(index);
                            setQusContext(item?.contextName);
                            setContextType(item?.type);
                            setChatData([]);
                            setClickedId("");
                          }}
                        >
                          {item?.contextDisplayName}
                        </button>
                      ))
                    : contextList?.contextList?.map((item, index) => (
                        <button
                          key={index}
                          className={`${
                            item?.accessForOthers === true ? "" : "coming-soon"
                          }${index === contextIndex ? " active" : ""} `}
                          type="text"
                          disabled={item?.accessForOthers ? false : true}
                          onClick={() => {
                            setContextIndex(index);
                            setQusContext(item?.contextName);
                            setContextType(item?.type);
                            setChatData([]);
                            setClickedId("");
                          }}
                        >
                          {item?.contextDisplayName}
                        </button>
                      ))}
                </div>
              </>
            )}
            <div className="search-history">
              {localStorage?.getItem("email") ? (
                <ExpandCollapse
                  title="Search History"
                  fetchHistory={fetchHistory}
                >
                  {history?.map((query, index) => (
                    <p
                      key={index}
                      onClick={() =>
                        historyChat(query?.chat_id, index, contextType)
                      }
                      title={query?.chat_name}
                      className={`mb-1 px-0 text-truncate cursor-pointer px-2 ${
                        index === activeIndex ? "active" : ""
                      }`}
                    >
                      {query?.chat_name}
                    </p>
                  ))}
                </ExpandCollapse>
              ) : (
                <WithoutLoginBlock />
              )}
            </div>
          </div>
        </aside>
        <div className="ai-responses-area border-start-0 border-end-0 ">
          <Header
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            chatData={chatData}
            setChatData={setChatData}
            setClickedId={setClickedId}
          />
          <QusAns
            chatData={chatData}
            clickedId={clickedId}
            qusContext={qusContext}
            contextType={contextType}
            history={history}
            setHistory={setHistory}
            setActiveIndex={setActiveIndex}
          />
        </div>
        <SingleAd />
      </main>
      {isLoggedIn && (
        <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      )}
    </>
  );
};
export default Layout;
