import React, { useState, useEffect } from "react";
import "./qus-ans.scss";
import { PC_LINK } from "../utils/globalFunctions";
import { Link } from "react-router-dom";
import {
  ApiRef,
  CopyAns,
  DownloadExcel,
  Mailers,
  RegenarateBtn,
  ShareMsg,
} from "./bottomBtns/RegenerateBtn";
import { PcAssitant } from "../utils/imgPaths";
import {
  IconEdit,
  InfoIcon,
  PcRoboAvtr,
} from "../components/ui-component/SvgIcon";
import AssistantLoader from "../components/ui-component/AssistantLoader";
import * as XLSX from "xlsx";
import { AlertPopup, AlertPopup2 } from "../components/ui-component/AlertPopup";

const Airesponse = ({
  message,
  isUser,
  usr_qus,
  query,
  triggerFunction,
  isFetching,
}) => {
  const [content, setContent] = useState([]);
  const [hasTable, setHasTable] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [secondaryAlert, setSecondaryAlert] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const apiResponse = message;
  useEffect(() => {
    const parseResponse = (response) => {
      const sections = response?.split("\n\n");
      const parsedContent = sections?.map((section) => {
        if (section?.includes("|")) {
          return parseTable(section);
        } else {
          return { type: "text", content: section };
        }
      });
      const tableFound = parsedContent?.some((item) => item?.type === "table");
      setHasTable(tableFound);
      return parsedContent;
    };

    const parseTable = (tableText) => {
      const lines = tableText
        ?.split("\n")
        ?.filter((line) => line?.trim().length > 0);

      let headers = [];
      const tableData = [];

      for (let i = 0; i < lines.length; i++) {
        const row = lines[i]
          ?.split("|")
          ?.map((col) => col?.trim())
          ?.filter(
            (col, index, arr) =>
              !(col === "" && (index === 0 || index === arr.length - 1))
          );

        if (i === 1) continue;

        if (
          row.every(
            (cell) =>
              cell === "" || cell === "..." || cell.match(/^(\s*\.\s*)+$/)
          )
        ) {
          continue;
        }

        if (i === 0) {
          headers = row;
        } else if (row?.length === headers?.length) {
          const rowData = {};
          headers?.forEach((header, index) => {
            rowData[header] = row[index] || "";
          });
          tableData?.push(rowData);
        }
      }

      return { type: "table", headers, rows: tableData };
    };
    setContent(parseResponse(apiResponse));
  }, [apiResponse]);

  const downloadExcel = () => {
    const tableData = content?.filter((item) => item?.type === "table");
    const introText = content?.find((item) => item?.type === "text")?.content;
    if (tableData.length === 0) {
      console.error("No table data to export.");
      return;
    }

    const workbook = XLSX.utils.book_new();

    tableData.forEach((table, index) => {
      const tableArray = [];
      tableArray.push([usr_qus || ""]);
      tableArray.push([]);
      if (introText !== undefined) {
        tableArray.push([introText || ""]);
        tableArray.push([]);
      }
      tableArray.push(table.headers);
      table.rows.forEach((row) => {
        tableArray.push(table.headers.map((header) => row[header] || ""));
      });

      const worksheet = XLSX.utils.aoa_to_sheet(tableArray);

      const colWidths = table.headers.map((header, colIndex) => {
        const maxLength = Math.max(
          ...tableArray?.map((row) =>
            row[colIndex] ? row[colIndex].length : 1
          )
        );
        return { wch: maxLength + 1 };
      });
      worksheet["!cols"] = colWidths;

      Object?.keys(worksheet).forEach((cell) => {
        if (
          worksheet[cell] &&
          typeof worksheet[cell] === "object" &&
          !cell.startsWith("!")
        ) {
          worksheet[cell].s = {
            font: { name: "Arial", sz: 12 },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "left",
            },
          };
        }
      });

      table?.headers?.forEach((header, headerIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ c: headerIndex, r: 2 });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: { bold: true, name: "Arial", sz: 12 },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, `Table-${index + 1}`);
    });

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "pharmacompass_ai_table.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderContent = (content) => {
    const handleCheckboxChange = (row, isChecked) => {
      const rowData = Object.values(row)
        .filter((value) => value.trim() !== "")
        .map((value) => "##" + value.replace(/\s+/g, "_"))
        .join("+");

      console.log("Selected row data:", rowData);

      if (isChecked) {
        setSelectedRows((prev) => [...prev, rowData]);
      } else {
        setSelectedRows((prev) =>
          prev.filter((selected) => selected !== rowData)
        );
      }
    };

    return content?.map((item, index) => {
      if (item?.type === "text") {
        return (
          <p key={index}>
            {item?.content === "Assistant is typing" ? (
              <AssistantLoader />
            ) : (
              item?.content
            )}
          </p>
        );
      } else if (item?.type === "table") {
        return (
          <table border="1" cellPadding="5" key={index}>
            <thead>
              <tr>
                {item?.headers?.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {item?.rows?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    {alertPopup === true && (
                      <input
                        type="checkbox"
                        className="m-0 ms-1 me-2"
                        value={Object?.values(row)?.join("+")}
                        onChange={(e) =>
                          handleCheckboxChange(row, e?.target?.checked)
                        }
                      />
                    )}
                    {row[item?.headers[0]]}
                  </td>
                  {item?.headers?.slice(1)?.map((header, colIndex) => (
                    <td key={colIndex + 1}>{row[header]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      return null;
    });
  };

  const handleSubmit = (data) => {
    setSelectedRows([]);
    console.log("new Selected rows to submit:", selectedRows);
    setAlertPopup(false);
    if (data) {
      setSecondaryAlert(true);
    }
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  }
  return (
    <>
      <div className={`${isUser ? "mb-4" : "mb-4 d-flex"}`}>
        {/* {!isUser && (
          <img
            src={PcAssitant}
            alt="PharmaCompass-AI-icon"
            className="img-rounded border rounded-3 me-2 p-1"
            height={48}
            width={48}
          />
        )} */}
        <div
          className={`${
            isUser
              ? "d-flex align-items-center justify-content-end isUser"
              : "res-con"
          }`}
        >
          {isUser && (
            <button className="edit-qus">
              <IconEdit />
            </button>
          )}
          <div className={`${isUser ? "qus-text" : "res-text mb-2"}`}>
            {!isUser ? (
              <span className="d-flex align-items-center gap-2 mb-2">
                <PcRoboAvtr />
                <span style={{ color: "var(--btnbg)" }}>PharmaCompass AI</span>
              </span>
            ):
            <span className="d-flex align-items-center gap-2 mb-2">
            <span className="name-pipe">{(localStorage?.getItem('firstName').charAt(0))}{(localStorage?.getItem('lastName').charAt(0))}</span>
            <span style={{ color: "var(--pcBlue)" }}>Your question</span>
          </span>
            }
            {renderContent(content)}
          </div>

          {!isUser && message !== "Assistant is typing" && (
            <div className={`text-end`}>
              <p className="mb-2 fs-14">
                See more
                <Link
                  to={PC_LINK}
                  target="_blank"
                  className="pc-link text-decoration-none"
                  rel="noreferer noopener"
                >
                  @PharmaCompass
                </Link>
                {/* <span className={`ms-2 ${query === null ? "d-none" : ""}`}>
                  <button
                    className="bg-transparent border-0 p-0 cursor-pointer"
                    onClick={() => copyToClipboard(query)}
                    title={query}
                  >
                    <InfoIcon />
                  </button>
                </span> */}
              </p>
              <div className="d-flex justify-content-end action-btns">
                <RegenarateBtn
                  isFetching={isFetching}
                  triggerFunction={triggerFunction}
                  usr_qus={usr_qus}
                />
                <ShareMsg />
                <CopyAns
                  isFetching={isFetching}
                  copyFunc={copyToClipboard}
                  message={message}
                />
                <Mailers
                  usr_qus={usr_qus}
                  message={message}
                  setAlertPopup={setAlertPopup}
                  isFetching={isFetching}
                />
                <DownloadExcel
                  hasTable={hasTable}
                  downloadExcel={downloadExcel}
                  isFetching={isFetching}
                />
                <ApiRef />
              </div>
            </div>
          )}
        </div>
      </div>
      {alertPopup && (
        <AlertPopup
          title={usr_qus}
          content={renderContent(content)}
          setAlertPopup={setAlertPopup}
          submitFunction={() => handleSubmit(hasTable)}
          isDisabled={selectedRows}
          buttonName={`${hasTable ? "Submit" : "Close"}`}
          hasTable={hasTable}
          setSelectedRows={setSelectedRows}
        />
      )}
      {secondaryAlert && (
        <AlertPopup2
          title={<h3 className="mb-0">Request sent successfully.</h3>}
          setAlertPopup={setSecondaryAlert}
          buttonName="Close"
          submitFunction={() => {
            setSecondaryAlert(false);
          }}
          content={
            <p className="lh-base" style={{ color: "var(--textGray)" }}>
              We will send you all the requested details to your registered
              mail. Please check your mailbox.
            </p>
          }
        />
      )}
    </>
  );
};

export default Airesponse;
