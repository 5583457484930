import React from "react";
import { Link } from "react-router-dom";
const SingleAd = (props) => {
  const userAds = [
    {
      url: "https://www.pharmacompass.com/image/ads250/athena-pharmaceutiques-m-4379.gif",
      Vburl:
        "https://www.pharmacompass.com/pharma-services/analytical/athena-pharmaceutiques",
    },
    {
      url: "https://www.pharmacompass.com/image/ads250/evonik-m-5450.gif",
      Vburl:
        "https://www.pharmacompass.com/pharma-services/drug-product-fdf-manufacturing-services/evonik",
    },
    {
      url: "https://www.pharmacompass.com/image/ads250/mikart-m-13701.gif",
      Vburl:
        "https://www.pharmacompass.com/pharma-services/analytical/mikart",
    },
  ];

  return (
    <>
      <aside className="ads-view px-3" aria-label="advertisements">
    <div class="" style={{height: 67}}></div>
        {userAds?.map((data, index) => {
          return (
            <div className="mb-3" key={index}>
              <Link
                to={data?.Vburl}
                target="_blank"
                rel="noopener noreferrer"
                className="ad-imgs"
              >
                <img
                  src={data?.url}
                  aria-label="advertisement-img"
                  alt="advertisement-img"
                  className="img-responsive ad-img"
                  height={192}
                  width={192}
                />
              </Link>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  <Link
                    to={data?.Vburl}
                    target="_blank"
                    className="inherit-color text-decoration-none"
                  >
                    Virtual Booth
                  </Link>
                </p>
                {/* <p>|</p> */}
                <p className="mb-0 isDisabled">
                  <Link to={"#"} className="inherit-color text-decoration-none">
                    Contact
                  </Link>
                </p>
              </div>
            </div>
          );
        })}
      </aside>
    </>
  );
};
export default SingleAd;
