import React, { useState } from "react";
import { BackRotate, HistoryIcon, Load } from "./ui-component/SvgIcon";

const ExpandCollapse = ({ children, title, fetchHistory }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expand-collapse text-truncate d-flex flex-column-reverse">
      <button
        onClick={toggleExpand}
        className="expand-button mb-0 d-flex align-items-center justify-content-between px-2"
      >
        <span className="d-flex align-items-center">
          {fetchHistory ? <Load /> : <HistoryIcon />}
          {title}
        </span>
        {isExpanded ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="9"
            viewBox="0 0 14 10"
            fill="none"
          >
            <path d="M18 0H0L9 9L18 0Z" fill="var(--pcBlue)" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="9"
            viewBox="0 0 34 10"
            fill="none"
          >
            <path d="M37 18H0L18.5 0L37 18Z" fill="var(--pcBlue)" />
          </svg>
        )}
      </button>
      <div
        className={`expand-content ${
          isExpanded ? "expanded-div" : "collapsed-div"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandCollapse;
