import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tooltip from "./ui-component/Tooltip";
import { LogoutIcon, NewChatIcon } from "./ui-component/SvgIcon";
const Header = ({ setIsLoggedIn, setChatData, setClickedId }) => {
  const LogoutSession = () => {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("client");
    localStorage.removeItem("level");
    localStorage.setItem("logout", Date.now());
    window.location.reload();
  };

  const Resetchat = () => {
    setChatData([]);
    setClickedId("");
  };
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event?.key === "logout") {
        window.location.href = "/chatbot";
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <header className="d-flex align-items-center justify-content-between border-bottom-0 px-0 py-2">
        {/* <h1 className="mb-0" style={{ lineHeight: 1 }}>
          PHARMACOMPASS AI
        </h1> */}
        <img src="https://www.pharmacompass.com/dev/image/vb/pharmacompass-white.png?v=4" alt="logo" height={52}/>
        <div className="d-flex">
          <Link to="#" className="">
            <Tooltip content={"New Chat"} position="bottom">
              <button
                className={`${
                  localStorage?.getItem("email") ? "" : "d-none"
                } login px-2 py-1`}
                onClick={Resetchat}
                disabled={localStorage?.getItem("email") ? false : true}
              >
                <NewChatIcon />
              </button>
            </Tooltip>
          </Link>
          <Link to="#" className="text-decoration-none mx-3">
            {localStorage.getItem("email") ? (
              <div className="btn-groun">
                <button className="login d-flex gap-2 px-2">
                  <span className="d-block text-truncate" style={{ width: 80 }}>
                    {localStorage?.getItem("firstName")
                      ? localStorage?.getItem("firstName")
                      : `User`}
                  </span>
                </button>
                <button
                  className="login d-flex gap-2 px-2"
                  title="Logout"
                  onClick={LogoutSession}
                >
                  <LogoutIcon />
                </button>
              </div>
            ) : (
              <button
                className="login"
                onClick={() => {
                  setIsLoggedIn(true);
                }}
              >
                Login
              </button>
            )}
          </Link>
          <Link to="#" target="_blank" className="">
            <button className="login isDisabled" disabled>
              PC Coins
            </button>
          </Link>
        </div>
      </header>
    </>
  );
};
export default Header;
