import React from "react";

export const AlertPopup = ({
  title = "",
  content = "",
  setAlertPopup,
  submitFunction = setAlertPopup(true),
  buttonName = "Ok, got it!",
  isDisabled,
  hasTable,
  setSelectedRows
}) => {
  
  return (
    <>
      <div className="alert-container border shadow-lg">
        <div className="position-relative">
          <button
            type="reset"
            className="ml-end bg-transparent border-0 cursor-pointer position-absolute end_10 top_10"
            onClick={() => {setAlertPopup(false);setSelectedRows([])}}
          >
            ✖
          </button>
          <div className="d-flex flex-column gap-3 res-text bg-transparent border-0">
            <h2 className="title mb-0 lh-1">{title}</h2>
            <div className="content mt-3 mb-1">{content}</div>
            <button
              type="button"
              className={`login mw-160 ${
                hasTable === true && isDisabled?.length===0 ? "isDisabled" : ""
              }`}
              onClick={
                hasTable === true && isDisabled?.length === 0
                  ? undefined
                  : submitFunction
              }
              disabled={
                hasTable === true && isDisabled?.length === 0 ? true : false
              }
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
      <div className="overlay-fill" onClick={() => setAlertPopup(false)}></div>
    </>
  );
};

export const AlertPopup2 = ({
  title = "",
  content = "",
  setAlertPopup,
  submitFunction = setAlertPopup(true),
  buttonName = "Ok, got it!",
}) => {
  return (
    <>
      <div className="alert-container alert-container-2 border shadow-lg">
        <div className="position-relative">
          <div className="d-flex flex-column gap-3 res-text bg-transparent border-0">
            <div className="mail-sent mt-3"></div>
            <div className="content mt-2 mb-1 text-center">
              {title}
              {content}
            </div>
            <button
              type="button"
              className="login mw-160"
              onClick={submitFunction}
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
      <div className="overlay-fill" onClick={() => setAlertPopup(false)}></div>
    </>
  );
};
