import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Airesponse from "./AiResponse";
import { send } from "../utils/imgPaths";
import { PC_API_LINK } from "../utils/globalFunctions";
import {AlertPopup} from "../components/ui-component/AlertPopup";
import { ChevDown, PcRobo, PcRobo2, Send } from "../components/ui-component/SvgIcon";

const QusAns = ({
  chatData,
  clickedId,
  qusContext,
  contextType,
  history,
  setHistory,
  setActiveIndex,
}) => {
  const [hovered, setHovered] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [chatId, setChatId] = useState("");
  const [messages, setMessages] = useState([]);
  const [msgJson, setMsgJson] = useState();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeArea, setActiveArea] = useState(true);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useLayoutEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    };
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const userLoggedIn = !!localStorage?.getItem("email");
    setActiveArea(!userLoggedIn || isFetching);
  }, [isFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        setShowScrollToBottom(scrollTop < scrollHeight - clientHeight - 400);
      }
    };
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (chatData) {
      const updatedMessages = chatData
        ?.map((msg) => [
          { text: msg?.user_question, isUser: true },
          {
            text: msg?.assistant_response,
            isUser: false,
            usr_qsn: msg?.user_question,
            query: msg?.query,
          },
        ])
        .flat();
      setMessages(updatedMessages);
      setChatId(chatData?.clickedId ? chatData?.clickedId : chatId);
      setMsgJson(chatData);
    }
  }, [chatData]);

  const getChatHistory = async (contextType) => {
    const url = "https://api.pharmacompass.ai/get_chat_history/";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
          type: contextType && contextType !== undefined ? contextType : "API",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const jsonResponse = await response.json();
      setHistory(jsonResponse?.chat_history);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };

  const handleSubmit = async (e, usrQsn) => {
    e.preventDefault();

    const promptToUse = usrQsn || prompt;

    if (!promptToUse) {
      setAlertPopup(true)
      return;
    }

    setPrompt("");
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: promptToUse, isUser: true },
      {
        text: "Assistant is typing",
        isUser: false,
        isTyping: true,
        usr_qsn: promptToUse,
      },
    ]);

    setIsFetching(true);

    try {
      const res = await fetch(
        `${PC_API_LINK}/${qusContext === "" ? "chat" : qusContext}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: localStorage?.getItem("email"),
            type: contextType,
            prompt: promptToUse,
            chat_id:
              clickedId && clickedId !== undefined && clickedId !== ""
                ? clickedId
                : !clickedId && chatId
                ? chatId
                : "",
          }),
        }
      );

      const data = await res.json();

      setMsgJson(data);
      setChatId(data?.chat_id);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: data?.assistant,
          isUser: false,
          query: data?.query,
          usr_qsn: promptToUse,
        },
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { text: "Error fetching response", isUser: false },
      ]);
    } finally {
      setIsFetching(false);
      setTimeout(() => {
        getChatHistory(contextType);
        if (chatId === "") {
          setActiveIndex(0);
        }
      }, 2000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="messages-container ai-responses px-3 pt-3"
        ref={chatContainerRef}
      >
        {messages?.map((msg, index) => (
          <Airesponse
            key={index}
            message={msg?.text}
            usr_qus={msg?.usr_qsn}
            query={msg?.query}
            isUser={msg?.isUser}
            triggerFunction={handleSubmit}
            clickedId={clickedId}
            dataJson={msgJson}
            isFetching={isFetching}
          />
        ))}
        {messages.length === 0 && (
          <div className="text-center placehoder-ai">
            <div
              className="d-inline-block"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {hovered ? <PcRobo2 /> : <PcRobo />}
            </div>
            <p className="my-1">Ask PharmaCompass AI</p>
          </div>
        )}
        <div className="mt-5" ref={messagesEndRef} />
      </div>

      {/* scroll-to bottom start */}
      {showScrollToBottom && (
        <button
          onClick={scrollToBottom}
          className="back-to-bottom cursor-pointer"
        >
          <ChevDown/>
        </button>
      )}
      {/* scroll-to bottom ends */}

      <div className="d-flex user-query-n px-3 pb-3">
        <form
          id="user-query"
          onSubmit={handleSubmit}
          className="d-flex w-100 d-block border-blue rounded-pill "
        >
          <textarea
            className={`border-top-0 ${activeArea ? "cursor-no-drop" : ""}`}
            placeholder={`Ask PharmaCompass.AI`}
            value={prompt}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={activeArea}
            rows={1}
          ></textarea>
          <button
            className={`${
              prompt?.length === undefined ||
              (prompt?.length === 0 && "isDisabled-clear")
            } border-0 bg-transparent border-top-0 cursor-pointer me-2`}
            type="submit"
            disabled={
              activeArea ||
              prompt?.length === undefined ||
              (prompt?.length === 0 && true)
            }
          >
            {/* <img
              src={send}
              alt="send-icon"
              className={activeArea ? "disabled-field" : ""}
              height={34}
              width={36}
            /> */}
            <Send activeArea={`me-1 ${activeArea ? "disabled-field" : ""}`}/>
          </button>
        </form>
      </div>
      {alertPopup && <AlertPopup title={`Alert`} content={`Please enter some query first.`} setAlertPopup={setAlertPopup}/>}
    </>
  );
};

export default QusAns;
